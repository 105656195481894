import { useState, Fragment } from 'react';
import { Icon, IconButton, styled } from '@mui/material';
import { SearchIcon } from 'assets/images/svg/icon';
// import { topBarHeight } from 'app/utils/constant';

const SearchContainer = styled('div')(({ theme }) => ({
  // position: 'absolute',
  // top: 0,
  // left: 0,
  // zIndex: 9,
  // width: '100%',
  width: '289px',
  display: 'flex',
  alignItems: 'center',
  // height: topBarHeight,
  height: '40px',
  // background: theme.palette.primary.main,
  background: '#ffffff',
  border: '1px solid #c4c4c4',
  borderRadius: '8px',
  padding: '0px 10px'
  // color: theme.palette.text.primary,
  // '&::placeholder': {
  //   color: theme.palette.text.primary
  // }
}));

const SearchInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  outline: 'none',
  fontSize: '1rem',
  paddingLeft: '5px',
  height: 'calc(100% - 5px)'
  // background: theme.palette.primary.main,
  // color: theme.palette.text.primary,
  // '&::placeholder': { color: theme.palette.text.primary }
}));

const SearchBox = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (
    <Fragment>
      {/* {!open && (
        <IconButton onClick={toggle}>
          <Icon sx={{ color: 'text.primary' }}>search</Icon>
        </IconButton>
      )} */}

      {/* {open && ( */}
      <SearchContainer>
        <SearchIcon />
        <SearchInput type="text" placeholder="Search here..." autoFocus />
        <IconButton onClick={toggle} sx={{ mx: 0, verticalAlign: 'middle' }}>
          <Icon sx={{ color: 'text.primary' }}>close</Icon>
        </IconButton>
      </SearchContainer>
      {/* )} */}
    </Fragment>
  );
};

export default SearchBox;
