import Mock from '../mock';
import shortId from 'shortid';

const PatientDB = {
  list: [
    {
      "id": "e3a9b236-6e09-4809-b48b-770dc30327ee",
      "patientID": "de78001d-665e-4716-a0ac-27198e049913",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Pat",
      "lastName": "Pat",
      "gender": "Male",
      "dateOfBirth": "04/21/1993",
      "patientAge": "25",
      "createdDate": "2023-10-23T18:58:54-04:00"
  },
  {
      "id": "a6149de7-a2de-41c7-94cf-0cfa80f823f8",
      "patientID": "f791a670-0fd6-42cd-b1c0-31d6e2f507e1",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "Test",
      "gender": "Male",
      "dateOfBirth": "04/21/1993",
      "patientAge": "25",
      "createdDate": "2023-10-23T19:04:55-04:00"
  },
  {
      "id": "bba2a9b3-2b46-40e4-bf50-3cf57fb2865e",
      "patientID": "5a8c717b-eb3f-4853-8c33-aced1211c0fc",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Pat",
      "lastName": "Pat",
      "gender": "Male",
      "dateOfBirth": "04/21/1993",
      "patientAge": "25",
      "createdDate": "2023-10-24T12:30:27-04:00"
  },
  {
      "id": "1677c383-2f68-4eba-b12f-d73fae29e29a",
      "patientID": "201bdc77-6bf0-4c1b-9620-1bd1c3cb0e59",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Pat",
      "lastName": "Pat",
      "gender": "Male",
      "dateOfBirth": "04/21/1993",
      "patientAge": "25",
      "createdDate": "2023-10-27T10:29:28-07:00"
  },
  {
      "id": "ff0d51d2-8179-43c0-b5ca-90579ba138d4",
      "patientID": "139ba4b2-4b82-4e09-b1de-8afc0d2ec659",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2023-10-27T10:35:00-07:00"
  },
  {
      "id": "0a22c77a-4e1f-4b04-9579-e0761cf41cde",
      "patientID": "c8a0ed11-4791-4efc-826c-feedbe6f8371",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2023-10-27T10:36:25-07:00"
  },
  {
      "id": "9d389211-df79-4e15-a996-c27da7f3219d",
      "patientID": "87a4a8e0-a763-49a1-a668-04c61ccc9b91",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Health Test",
      "lastName": "Health Test",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2023-11-10T12:44:19-05:00"
  },
  {
      "id": "a9e2b7a7-48ec-4fc4-baac-3d930654459c",
      "patientID": "a5ae6089-6cd7-46d5-8ffb-4c524d229784",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Pat",
      "lastName": "Pat",
      "gender": "Male",
      "dateOfBirth": "04/21/1993",
      "patientAge": "25",
      "createdDate": "2023-11-16T21:32:08-05:00"
  },
  {
      "id": "f7fa823c-08f1-4234-ba83-1e59aab7bc13",
      "patientID": "2acbd8ee-d388-4e3c-9307-b3efb32480c9",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "81c47afd-9d62-4d0f-910e-32bae800779c",
      "patientID": "6e574e71-1676-4793-a3a0-8049e507b5f5",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "62106c29-e75a-4249-8b0e-fa872d25c906",
      "patientID": "8fe8c4df-e602-4509-83cc-0031511d84f5",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "c79e0719-d57e-4092-8155-013e99f95f9b",
      "patientID": "c04a8955-335d-4099-85ac-d6c97fd45cbc",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-06T14:05:15-05:00"
  },
  {
      "id": "259787a8-6038-43c2-874a-76f418191126",
      "patientID": "9a8eb0dc-2053-42f2-992f-8d5b375d1210",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-08T19:44:46-05:00"
  },
  {
      "id": "8ba795c9-59ea-4da2-bf60-e798d6b8dfcc",
      "patientID": "61bff136-ba94-414d-b25a-4f660d730561",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "New Patient 3",
      "lastName": "New Patient 3",
      "gender": "Male",
      "dateOfBirth": "04/21/1993",
      "patientAge": "25",
      "createdDate": "2024-01-08T22:48:18-05:00"
  },
  {
      "id": "be295342-564d-4ada-832d-ba13826a3a72",
      "patientID": "f869e45c-fde8-40b3-9024-94de520f9c83",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "New Patient",
      "lastName": "New Patient",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-09T16:25:20-05:00"
  },
  {
      "id": "9be23b27-3833-495f-80b9-19383e9d9f0a",
      "patientID": "2812e86b-5974-42ac-8c79-f313d9277b2e",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "another patient",
      "lastName": "another patient",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-09T16:26:43-05:00"
  },
  {
      "id": "4e2c3b57-cb1e-4417-8368-d2d4a68543e8",
      "patientID": "270dc77d-402e-48d5-b0f9-a4cddc2f77fb",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Another one",
      "lastName": "Another one",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-09T16:29:25-05:00"
  },
  {
      "id": "f1a49dd3-a06f-4f53-b8cb-428de18fc090",
      "patientID": "17fd603d-da68-4135-b6ea-1300815fb3e0",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Pat",
      "lastName": "Pat",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-09T16:43:31-05:00"
  },
  {
      "id": "73a0921b-225d-490d-a171-c38ef09f6a43",
      "patientID": "56efb13e-bd50-4c95-bf4a-612c8c004a26",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "670c6997-52f1-4be9-a1e4-164e26e05dce",
      "patientID": "412e5a35-2daf-40f6-a017-923ac0cad408",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": null,
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "3c42bfac-af8c-480a-b3a5-ad61fd96a87e",
      "patientID": "5284b99d-93ed-4e7f-8876-a4e8b9a65c0d",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": null,
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "e162d020-3520-4f25-8b8f-97531cec1aaf",
      "patientID": "31e8bc20-6274-45f2-8dc1-0f6b202f1f4b",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": null,
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "791ed887-b59c-48e0-b2e3-59bb7fa92315",
      "patientID": "c51c617a-7aec-474a-be8b-6cb094d282fb",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "3621ab7c-245c-4c58-b30d-fe8fe3053d28",
      "patientID": "4e4b5adf-20a7-4ed6-a560-d11ff35d61ce",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "767344e7-23b6-424e-a2e6-66e857c031c3",
      "patientID": "8cc20de2-a59b-489e-b187-7e02ece522a5",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "9de4dc11-5cec-4fc6-87f6-ca3c17a3c586",
      "patientID": "f6029652-28a4-4efe-869f-b2de5cf80d31",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Test",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": null,
      "createdDate": ""
  },
  {
      "id": "ca826ace-a986-4d64-ab79-51745a697377",
      "patientID": "3e8e08a4-8398-4546-beb2-56051905a13c",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Another ",
      "lastName": "Another ",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-11T14:30:05-05:00"
  },
  {
      "id": "50faf89f-ec3b-4cd3-8459-c6b1069dc7dc",
      "patientID": "a3d5ecad-e5a5-44a4-b218-e26cfda851f9",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Alberto",
      "lastName": "Alberto",
      "gender": "Male",
      "dateOfBirth": "10-01",
      "patientAge": "25",
      "createdDate": "2024-01-14T22:13:27-05:00"
  },
  {
      "id": "ac1a7cd6-de25-468d-91be-f95bca72d910",
      "patientID": "e0ee3a28-9aa3-473a-94ba-daf84a8b6a48",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Alberto",
      "lastName": "Alberto",
      "gender": "Male",
      "dateOfBirth": "10/02/01",
      "patientAge": "25",
      "createdDate": "2024-01-21T15:06:29-05:00"
  },
  {
      "id": "27403ffa-1028-4c51-b47a-9df0222b0f65",
      "patientID": "cb48738a-b3dd-474f-8e70-936c24ef3bb4",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "",
      "lastName": "",
      "gender": "Male",
      "dateOfBirth": "",
      "patientAge": "25",
      "createdDate": "2024-01-21T15:09:41-05:00"
  },
  {
      "id": "4a62a3f5-aa79-431c-9679-3ea9e605f3fc",
      "patientID": "779f60c0-c18e-4b3a-a0fb-7d6f419ee726",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Alberto 2",
      "lastName": "Alberto 2",
      "gender": "Male",
      "dateOfBirth": "10/02/2002",
      "patientAge": "25",
      "createdDate": "2024-01-21T15:14:34-05:00"
  },
  {
      "id": "132635cf-8e6d-48da-8d87-276e531bf434",
      "patientID": "8b613e9d-878c-4105-a7f2-69d5d7a09eb3",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "And",
      "lastName": "And",
      "gender": "Male",
      "dateOfBirth": "Qwweeer",
      "patientAge": "25",
      "createdDate": "2024-01-25T21:11:28-05:00"
  },
  {
      "id": "5f9cf3aa-f0e2-4b00-903d-285b479bd9f4",
      "patientID": "d620e958-b341-49a7-906a-0d9b44f12013",
      "creatorID": "4f1b8f79-0d59-4d17-af27-076c9effbc6c",
      "firstName": "Lax",
      "lastName": "Lax",
      "gender": "Male",
      "dateOfBirth": "Aaaaaaa",
      "patientAge": "25",
      "createdDate": "2024-01-25T21:13:29-05:00"
  }
  ]
};

Mock.onGet('/api/patients/4f1b8f79-0d59-4d17-af27-076c9effbc6c').reply(() => {
  const response = PatientDB.list;
  return [200, response];
});

Mock.onPost('/api/patients/add').reply(() => {
  const response = PatientDB.list;
  return [200, response];
});

Mock.onPost('/api/patients/delete').reply((config) => {
  let { id } = JSON.parse(config.data);
  console.log(config.data);

  const response = PatientDB.list.filter((patients) => patients.id !== id);
  PatientDB.list = [...response];
  return [200, response];
});

Mock.onPost('/api/patients/delete-all').reply(() => {
  PatientDB.list = [];
  const response = PatientDB.list;
  return [200, response];
});
