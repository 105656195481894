export const API_URL = {
  LOGIN: '/v1/user/login',
  SIGNUP: '/v1/user/create',
  FORGOT_PASSWORD: '/v1/email/sendForgotPasswordEmail',
  RESET_PASSWORD: '/v1/user/changeForgotPassword',
  RESEND_OTP: '/v1/email/sendOTPEmail',
  VALIDATE_OTP: 'v1/user/validateOTP',
  CHANGE_PASSWORD: 'v1/user/changePassword',
  UPDATE_USER: 'v1/user/update',
  CREATE_NOTE: 'v1/notes/create',
  GET_NOTES: '/v1/notes/get', // userid
  GET_NOTES_ID: '/v1/notes/get', //single note id
  UPDATE_NOTE: 'v1/notes/update',
  DELETE_NOTE: 'v1/notes/delete'
};
