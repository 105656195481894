import { Box, ButtonBase, Icon, styled, useMediaQuery, useTheme } from '@mui/material';
import useSettings from 'app/hooks/useSettings';
import React, { Fragment, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Paragraph, Span } from '../Typography';
import VerticalNavExpansionPanel from './VerticalNavExpansionPanel';
import clsx from 'clsx';
import { useEffect } from 'react';

const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
  fontSize: '12px',
  marginTop: '20px',
  marginLeft: '15px',
  marginBottom: '10px',
  textTransform: 'uppercase',
  display: mode === 'compact' && 'none',
  color: theme.palette.text.secondary
}));

const ExtAndIntCommon = {
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '4px',
  height: 44,
  whiteSpace: 'pre',
  marginBottom: '8px',
  textDecoration: 'none',
  justifyContent: 'space-between',
  transition: 'all 150ms ease-in',
  '&:hover': {
    backgroundColor: '#ceddef',
    color: '#c41e3a',
    borderRadius: '10px',
    '& svg path': {
      fill: '#c41e3a',
      stroke: '#c41e3a'
    }
  },
  '&.compactNavItem': {
    overflow: 'hidden',
    justifyContent: 'center !important'
  },
  '& .icon': {
    // fontSize: '18px',
    paddingLeft: '16px',
    paddingRight: '16px',
    verticalAlign: 'middle'
  }
};
const ExternalLink = styled('a')(({ theme }) => ({
  ...ExtAndIntCommon,
  color: theme.palette.text.primary
}));

const InternalLink = styled(Box)(({ theme }) => ({
  '& a': {
    ...ExtAndIntCommon,
    color: theme.palette.text.primary
  },
  '& .navItemActive': {
    // backgroundColor: 'rgba(255, 255, 255, 0.16)'
    backgroundColor: '#ceddef',
    borderRadius: '10px',
    color: '#c41e3a',
    '& svg path': {
      fill: '#c41e3a',
      stroke: '#c41e3a'
    }
  }
}));

const StyledText = styled(Span)(({ mode }) => ({
  fontSize: '1.125rem',
  fontFamily: 'Avenir',
  paddingLeft: '0.8rem',
  display: mode === 'compact' && 'none'
}));

const BulletIcon = styled('div')(({ theme }) => ({
  // padding: '2px',
  marginLeft: '24px',
  marginRight: '8px',
  overflow: 'hidden',
  // borderRadius: '300px',
  width: '2px',
  height: '27px',
  borderRadius: '10px',
  background: theme.palette.text.primary
}));

const BadgeValue = styled('div')(() => ({
  padding: '1px 8px',
  overflow: 'hidden',
  borderRadius: '300px'
}));

const VerticalNav = ({ items }) => {
  const { settings, updateSettings } = useSettings();
  const { mode } = settings.LayoutSettings.leftSidebar;
  const updateSidebarMode = (sidebarSettings) => {
    updateSettings({ LayoutSettings: { leftSidebar: { ...sidebarSettings } } });
  };
  const [active, setActive] = useState('');
  const [expansionActive, setExpansionActive] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    let path = '';
    if (expansionActive === 'Create Note') {
      setActive('H&P');
      navigate('/notes/create?type=hp');
    } else if (expansionActive === 'Update Note') {
      setActive('Edit Note');
      navigate('/notes/edit');
    }
  }, [expansionActive]);

  const NavExpansionOnSmallerDevice = (children) => {
    if (!children && isMdScreen) {
      let left = settings.LayoutSettings.leftSidebar.left === 0 ? -230 : 0;
      updateSidebarMode({ left });
    }
  };

  const renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.type === 'label')
        return (
          <ListLabel key={index} mode={mode} className="sidenavHoverShow">
            {item.label}
          </ListLabel>
        );

      if (item.children) {
        return (
          <VerticalNavExpansionPanel
            mode={mode}
            item={item}
            key={index}
            expansionActive={expansionActive}
            setExpansionActive={setExpansionActive}
          >
            {renderLevels(item.children)}
          </VerticalNavExpansionPanel>
        );
      } else if (item.type === 'extLink') {
        return (
          <ExternalLink
            key={index}
            href={item.path}
            className={`${mode === 'compact' && 'compactNavItem'}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
              {(() => {
                if (item.icon) {
                  return <Icon className="icon">{item.icon}</Icon>;
                } else {
                  return <span className="item-icon icon-text">{item.iconText}</span>;
                }
              })()}
              <StyledText mode={mode} className="sidenavHoverShow">
                {item.name}
              </StyledText>
              <Box mx="auto"></Box>
              {item.badge && <BadgeValue>{item.badge.value}</BadgeValue>}
            </ButtonBase>
          </ExternalLink>
        );
      } else {
        return (
          <InternalLink key={index}>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                isActive
                  ? `${item.icon && 'navItemActive'} ${mode === 'compact' && 'compactNavItem'}`
                  : `${mode === 'compact' && 'compactNavItem'}`
              }
              onClick={() => {
                setActive(item.name);
                setExpansionActive('');
                NavExpansionOnSmallerDevice(item.children);
              }}
            >
              <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
                {item?.icon ? (
                  <Icon className="icon" sx={{ width: 36 }}>
                    {item.icon}
                  </Icon>
                ) : (
                  <Fragment>
                    {active === item.name && (
                      <BulletIcon
                        className={`nav-bullet`}
                        sx={{ display: mode === 'compact' && 'none' }}
                      />
                    )}
                  </Fragment>
                )}
                <StyledText
                  mode={mode}
                  className="sidenavHoverShow"
                  style={!item.icon && active !== item.name ? { paddingLeft: '46px' } : {}}
                >
                  {item.name}
                </StyledText>

                <Box mx="auto" />

                {/* {item.badge && (
                  <BadgeValue className="sidenavHoverShow">{item.badge.value}</BadgeValue>
                )} */}
              </ButtonBase>
            </NavLink>
          </InternalLink>
        );
      }
    });
  };

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default React.memo(VerticalNav);
