import Mock from '../mock';
import shortId from 'shortid';

const NotesDB = {
  list: [
    {
      id: 'b7775b4e-7e45-44c9-b768-d347ba7123c6',
      noteID: '217ae9dd-f029-402a-803d-1be929d91946',
      userID: '4f1b8f79-0d59-4d17-af27-076c9effbc6c',
      translatedText:
        'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness. On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable. Her vitals are notable for a blood pressure of 120/80, Tachycardia with a pulse of 120. However shes afebrile. Initial labs were notable for an elevated lipase, an elevated ESR and CRP. They were also notable for significant leukocytosis. In summary, we have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness. Differential diagnosis includes acute appendicitis versus bowel obstruction versus crohns flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.',
      promptType: 'accupuncture',
      content: {
        hpi: 'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness. On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable.',
        pmh: 'Diabetes, prior cholecystectomy, prior colectomy with end-ostomy.',
        ros: 'Denies recent fevers, chills, chest pain, shortness of breath, lightheadedness, or dizziness. Symptoms of abdominal pain, diarrhea, vomiting, and nausea present.',
        medications: '',
        allergies: '',
        familyHistory: '',
        socialHistory: '',
        physicalExam:
          'Right lower quadrant tenderness radiating to the umbilicus. Otherwise unremarkable.',
        vitals: { temperature: '', heartRate: '', bloodPressure: '120/80' },
        dataReview: {
          labs: 'Elevated lipase, elevated ESR and CRP, significant leukocytosis.',
          imaging: '',
          ekg: ''
        },
        summaryStatement:
          'We have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness.',
        assessmentAndPlan:
          "Differential diagnosis includes acute appendicitis versus bowel obstruction versus Crohn's flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.",
        patientEducation: '',
        followUp: ''
      },
      original:
        'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness. On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable. Her vitals are notable for a blood pressure of 120/80, Tachycardia with a pulse of 120. However she’s afebrile. Initial labs were notable for an elevated lipase, an elevated ESR and CRP. They were also notable for significant leukocytosis. In summary, we have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness. Differential diagnosis includes acute appendicitis versus bowel obstruction versus crohns flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.',
      createdDate: '',
      updatedDate: null,
      patient: {
        id: null,
        patientID: null,
        creatorID: null,
        firstName: 'Test',
        lastName: '',
        gender: 'Male',
        dateOfBirth: '2023-03-11',
        patientAge: null,
        createdDate: ''
      },
      analysis: {
        levelOfService: '4',
        improvementSuggestions: {
          medicalDecisionMaking:
            "Include more detailed discussion on the differential diagnosis and potential next steps for further evaluation and management. Specifically, elaborate on the reasoning behind considering acute appendicitis, bowel obstruction, and Crohn's flare. Additionally, mention the potential benefits and risks of obtaining abdominal imaging.",
          riskOfComplications:
            'Discuss the potential risks associated with IV fluid administration and pain medicine. Consider mentioning any specific monitoring requirements or precautions to be taken.',
          amountAndComplexityOfData:
            "Specify the unique tests that were ordered or considered to evaluate the patient's condition, such as imaging studies and any additional laboratory tests. Additionally, mention any relevant external sources or consultation with other healthcare professionals for test interpretation or management discussion."
        }
      }
    },
    {
      id: '4bacbfb4-7d8f-4412-95b8-97e813c6de22',
      noteID: 'bfb5f5b8-0141-48ef-a758-b291b313fa91',
      userID: '4f1b8f79-0d59-4d17-af27-076c9effbc6c',
      translatedText:
        'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness. On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable. Her vitals are notable for a blood pressure of 120/80, Tachycardia with a pulse of 120. However she’s afebrile. Initial labs were notable for an elevated lipase, an elevated ESR and CRP. They were also notable for significant leukocytosis. In summary, we have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness. Differential diagnosis includes acute appendicitis versus bowel obstruction versus crohns flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.',
      promptType: 'accupuncture',
      content: {
        hpi: 'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness.',
        pmh: 'She has a history of diabetes, prior cholecystectomy, and prior colectomy with end-ostomy.',
        ros: 'She denies any recent fevers, chills, chest pain, shortness of breath, lightheadedness, or dizziness.',
        medications: '',
        allergies: '',
        familyHistory: '',
        socialHistory: '',
        physicalExam:
          'On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable.',
        vitals: {
          temperature: '',
          heartRate: 'Tachycardia with a pulse of 120',
          bloodPressure: '120/80'
        },
        dataReview: {
          labs: 'Initial labs were notable for an elevated lipase, an elevated ESR and CRP. They were also notable for significant leukocytosis.',
          imaging: '',
          ekg: ''
        },
        summaryStatement:
          'In summary, we have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness.',
        assessmentAndPlan:
          'Differential diagnosis includes acute appendicitis versus bowel obstruction versus crohns flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.',
        patientEducation: '',
        followUp: ''
      },
      original:
        'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness. On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable. Her vitals are notable for a blood pressure of 120/80, Tachycardia with a pulse of 120. However she’s afebrile. Initial labs were notable for an elevated lipase, an elevated ESR and CRP. They were also notable for significant leukocytosis. In summary, we have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness. Differential diagnosis includes acute appendicitis versus bowel obstruction versus crohns flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.',
      createdDate: '',
      updatedDate: '',
      patient: {
        id: null,
        patientID: null,
        creatorID: null,
        firstName: 'Test',
        lastName: '',
        gender: 'Male',
        dateOfBirth: '',
        patientAge: null,
        createdDate: ''
      },
      analysis: {
        levelOfService: '4',
        improvementSuggestions: {
          medicalDecisionMaking:
            "Include more details about the patient's medical history and surgical history, such as the duration and management of diabetes and the specifics of the prior cholecystectomy and colectomy. Specify the criteria that led to the consideration of acute appendicitis, bowel obstruction, and Crohn's flare in the differential diagnosis.",
          riskOfComplications:
            "Elaborate on the potential risks associated with the patient's history and current symptoms, such as the risks of infection, perforation, or other complications related to abdominal surgery, and the possible consequences of delaying treatment.",
          amountAndComplexityOfData:
            'Specify the unique tests ordered for the patient and consider including other relevant data such as imaging findings, laboratory results, or consultation reports.'
        }
      }
    },
    {
      id: '0a9e5827-bdc5-4eed-8feb-2a7e7c6b8924',
      noteID: '624ca0ac-3d50-47c3-9ba3-71fa86b30678',
      userID: '4f1b8f79-0d59-4d17-af27-076c9effbc6c',
      translatedText:
        'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness. On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable. Her vitals are notable for a blood pressure of 120/80, Tachycardia with a pulse of 120. However she’s afebrile. Initial labs were notable for an elevated lipase, an elevated ESR and CRP. They were also notable for significant leukocytosis. In summary, we have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness. Differential diagnosis includes acute appendicitis versus bowel obstruction versus crohns flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.',
      promptType: 'accupuncture',
      content: {
        hpi: 'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness.',
        pmh: 'History of diabetes, prior cholecystectomy, prior colectomy with end-ostomy.',
        ros: 'Denies any recent fevers or chills, denies chest pain, shortness of breath, lightheadedness, or dizziness.',
        medications: '',
        allergies: '',
        familyHistory: '',
        socialHistory: '',
        physicalExam:
          'She has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable.',
        vitals: {
          temperature: 'afebrile',
          heartRate: 'Tachycardia - pulse of 120',
          bloodPressure: '120/80'
        },
        dataReview: {
          labs: 'Notable for an elevated lipase, elevated ESR and CRP, significant leukocytosis.',
          imaging: '',
          ekg: ''
        },
        summaryStatement:
          'We have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia. Her exam is remarkable for right lower quadrant tenderness.',
        assessmentAndPlan:
          "Differential diagnosis includes acute appendicitis versus bowel obstruction versus Crohn's flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.",
        patientEducation: '',
        followUp: ''
      },
      original:
        'Patient is a 20-year-old female with a history of diabetes, prior cholecystectomy, prior colectomy with end-ostomy who presents with abdominal pain. Her pain first started this morning when she woke up, associated with two episodes of diarrhea, one episode of vomiting, and ongoing nausea , she denies any recent fevers, or chills, and further denies any chest pain, shortness of breath, lightheadedness, or dizziness. On my exam she has right lower quadrant tenderness, radiating to the umbilicus. The remainder for exam is unremarkable. Her vitals are notable for a blood pressure of 120/80, Tachycardia with a pulse of 120. However she’s afebrile. Initial labs were notable for an elevated lipase, an elevated ESR and CRP. They were also notable for significant leukocytosis. In summary, we have a 42-year-old female with significant past medical history and past surgical history involving the abdomen who is coming in with abdominal pain that started this morning. She is afebrile, but has a tachycardia, her exam is remarkable for right lower quadrant tenderness. Differential diagnosis includes acute appendicitis versus bowel obstruction versus crohns flare. Will await the remainder of her labs. Will plan to obtain imaging of her abdomen. We will treat now with IV fluids and pain medicines. GI consult will be placed.',
      createdDate: '2023-03-11',
      updatedDate: '',
      patient: {
        id: null,
        patientID: null,
        creatorID: null,
        firstName: 'Test',
        lastName: '',
        gender: 'Male',
        dateOfBirth: '',
        patientAge: null,
        createdDate: ''
      },
      analysis: {
        levelOfService: '4',
        improvementSuggestions: {
          medicalDecisionMaking:
            "Include a more detailed assessment of the patient's past medical and surgical history, provide a thorough examination findings (e.g., palpation of other abdominal quadrants), and discuss potential complications or risks associated with the differential diagnoses.",
          riskOfComplications:
            "Specify the potential risks or complications associated with the patient's condition, such as the risk of bowel perforation or surgical complications.",
          amountAndComplexityOfData:
            'Include detailed information about the specific lab results, such as the values for elevated lipase, ESR, CRP, and leukocytosis. Additionally, mention any other relevant tests or diagnostic procedures that have been ordered or considered.'
        }
      }
    },
    {
      id: 'b9280f5d-432a-48be-bd4a-142918c97045',
      noteID: 'cedff3d6-c2ef-48d7-8370-9a1b7e46f6e5',
      userID: '4f1b8f79-0d59-4d17-af27-076c9effbc6c',
      translatedText: 'So The patient is a 25 year old male that has a history of diabetes.',
      promptType: 'Dermatology',
      content: {
        hpi: '',
        pmh: 'The patient has a history of diabetes.',
        ros: '',
        medications: '',
        allergies: '',
        familyHistory: '',
        socialHistory: '',
        physicalExam: '',
        vitals: { temperature: '', heartRate: '', bloodPressure: '' },
        dataReview: { labs: '', imaging: '', ekg: '' },
        summaryStatement: '',
        assessmentAndPlan: '',
        patientEducation: '',
        followUp: ''
      },
      original: 'So The patient is a 25 year old male that has a history of diabetes.',
      createdDate: '2024-01-11T14:31:09-05:00',
      updatedDate: '2024-01-11T14:31:09-05:00',
      patient: {
        id: null,
        patientID: null,
        creatorID: null,
        firstName: 'Another ',
        lastName: 'Another ',
        gender: 'Male',
        dateOfBirth: '',
        patientAge: '25',
        createdDate: '2024-01-11T14:30:05-05:00'
      },
      analysis: {
        levelOfService: '2',
        improvementSuggestions: {
          medicalDecisionMaking:
            "The note should include information about the patient's current diabetes management, any complications or worsening of the condition, and any treatment side effects.",
          riskOfComplications:
            "The note should mention the patient's risk of complications related to their diabetes, such as poor glycemic control, cardiovascular disease, or peripheral neuropathy.",
          amountAndComplexityOfData:
            'The note should mention any tests or results reviewed, such as blood glucose levels, HbA1c, or lipid panel. It should also include any external consultations or independent test interpretations.'
        }
      }
    }
  ]
};

// Mock.onGet('/api/notes/all').reply(() => {
//   const response = NotesDB.list;
//   return [200, response];
// });

// Mock.onGet('/v1/notes/get/009f8955-4230-4c36-a928-602708e01162/0/10').reply(() => {
//   const response = NotesDB.list;
//   return [200, response];
// });

// Mock.onPost('/api/notes/create').reply((config) => {
//   let note = JSON.parse(config.data);
//   NotesDB.push(note);
//   const response = NotesDB.list;
//   return [200, response];
// });

Mock.onPost('/v1/notes/create').reply((config) => {
  let note = JSON.parse(config.data);
  NotesDB.push(note);
  const response = NotesDB.list;
  return [200, response];
});

Mock.onPost('/v1/notes/update').reply((config) => {
  let { id } = JSON.parse(config.data);
  // fetch updated data to update the notes
  console.log(config.data);

  const response = NotesDB.list.map((notes) => {
    if (notes.id == id) {
      return { ...notes, ...{} };
    }
  });
  NotesDB.list = [...response];
  return [200, response];
});

Mock.onPost('/api/notification/delete').reply((config) => {
  let { id } = JSON.parse(config.data);
  console.log(config.data);

  const response = NotesDB.list.filter((notification) => notification.id !== id);
  NotesDB.list = [...response];
  return [200, response];
});

Mock.onPost('/api/notification/delete-all').reply(() => {
  NotesDB.list = [];
  const response = NotesDB.list;
  return [200, response];
});
