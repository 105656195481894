import useAuth from 'app/hooks/useAuth';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

axiosInstance.interceptors.request.use(
  (config) => {
    // const { token } = useAuth();
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = '*/*';
    // const token =
    //   'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJQU1Rva2VuIiwianRpIjoiZmFiY2RmYmEtM2ZmNy00NThkLWJmN2QtOGYzMmZmMTVjMTBiIiwiaWF0IjoiMDQvMDgvMjAyNCAxMjoxMToxMSIsInJvbGUiOiJ1c2VyIiwiZXhwIjoxNzEyNzUxMDcxLCJpc3MiOiJodHRwczovL215cG9ja2V0c2NyaWJlLmNvbSIsImF1ZCI6InBvY2tldHNjcmliZSJ9.if1muzpfDIGgLjUoqOLtClEAKMdhqFdLO6jiYZghe74';
    const token = getAccessToken();
    // config.headers['Authorization'] = `Bearer ${token}`;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (config) => {
    console.log('Response Config', config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
