import Mock from '../mock';
import shortId from 'shortid';

const NotificationDB = {
  list: [
    {
      id: shortId.generate(),
      heading: 'Alert',
      icon: { name: 'notifications', color: 'error' },
      timestamp: 1570702702573,
      title: 'New Note',
      subtitle: 'Note generated for patient John Smith',
      path: 'notes'
    },
    {
      id: shortId.generate(),
      heading: 'Alert',
      icon: { name: 'notifications', color: 'error' },
      timestamp: 1570702702573,
      title: 'Analysis Completed',
      subtitle: 'Billing analysis for patient Steve Johnson',
      path: 'notes'
    }
  ]
};

Mock.onGet('/api/notification').reply(() => {
  const response = NotificationDB.list;
  return [200, response];
});

Mock.onPost('/api/notification/add').reply(() => {
  const response = NotificationDB.list;
  return [200, response];
});

Mock.onPost('/api/notification/delete').reply((config) => {
  let { id } = JSON.parse(config.data);
  console.log(config.data);

  const response = NotificationDB.list.filter((notification) => notification.id !== id);
  NotificationDB.list = [...response];
  return [200, response];
});

Mock.onPost('/api/notification/delete-all').reply(() => {
  NotificationDB.list = [];
  const response = NotificationDB.list;
  return [200, response];
});
