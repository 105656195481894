import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import Layout from './components/Layout/Layout';

// Dashboard
const Dashboard = Loadable(lazy(() => import('app/views/dashboard/Dashboard')));

// Profile pages
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));
const OTPPage = Loadable(lazy(() => import('app/views/sessions/OtpPage')));
const ResetPassword = Loadable(lazy(() => import('app/views/sessions/ResetPassword')));

// Notes
const CreateNote = Loadable(lazy(() => import('app/views/notes/CreateNoteLayout')));
const EditNote = Loadable(lazy(() => import('app/views/notes/EditNoteLayout')));
const UpdateNote = Loadable(lazy(() => import('app/views/notes/UpdateNoteLayout')));
const ViewNotes = Loadable(lazy(() => import('app/views/notes/ViewNotesLayout')));

// Patients
const CreatePatient = Loadable(lazy(() => import('app/views/patients/CreatePatient')));
const EditPatient = Loadable(lazy(() => import('app/views/patients/EditPatient')));
const ViewPatients = Loadable(lazy(() => import('app/views/patients/ViewPatients')));
// Analytics
const Analytics = Loadable(lazy(() => import('app/views/analytics/Analytics')));
// Profile
const UserProfile = Loadable(lazy(() => import('app/views/profile/ProfileView')));
// Other
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
// Payment
const PaymentPage = Loadable(lazy(() => import('app/views/payment/stripePayment')));
const PaymentConfirmationPage = Loadable(
  lazy(() => import('app/views/payment/PaymentConfirmation'))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      // Dashboard
      //{ path: '/dashboard', element: <Dashboard />, auth: authRoles.admin },
      // Notes
      { path: '/notes/create', element: <CreateNote />, auth: authRoles.user },
      { path: '/notes/edit', element: <EditNote />, auth: authRoles.user },
      { path: '/notes/update/:id?', element: <UpdateNote />, auth: authRoles.user },
      { path: '/notes/view', element: <ViewNotes />, auth: authRoles.user },
      // Patients
      { path: '/patients/create', element: <CreatePatient />, auth: authRoles.user },
      { path: '/patients/edit', element: <EditPatient />, auth: authRoles.user },
      { path: '/patients/view', element: <ViewPatients />, auth: authRoles.user },
      // Analytics
      //{ path: '/analytics', element: <Analytics />, auth: authRoles.admin },
      // Payment
      //{ path: '/payment', element: <PaymentPage />, auth: authRoles.user },
      //{ path: '/payment-confirmation', element: <PaymentConfirmationPage />, auth: authRoles.user },
      // Profile
      { path: '/user-profile', element: <UserProfile />, auth: authRoles.user }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: '/session/otp', element: <OTPPage /> },
  { path: '/session/reset-password', element: <ResetPassword /> },

  // { path: '/', element: <Navigate to="dashboard" /> },
  { path: '/', element: <Navigate to="notes/view" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
