import { SuspenseWrapper } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { Layouts } from './index';

const Layout = (props) => {
  const { settings } = useSettings();
  const Layout = Layouts[settings.activeLayout];

  return (
    <SuspenseWrapper>
      <Layout {...props} />
    </SuspenseWrapper>
  );
};

export default Layout;
