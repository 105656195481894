import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { Theme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { SnackbarProvider } from 'notistack';
import routes from './routes';
import '../fake-db';

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <SnackbarProvider>
        <AuthProvider>
          <Theme>
            <CssBaseline />
            {content}
            {/* <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements> */}
          </Theme>
        </AuthProvider>
      </SnackbarProvider>
    </SettingsProvider>
  );
};

export default App;
