import { createContext, useEffect, useReducer, useState } from 'react';
import axiosInstance from 'app/services/AxiosInstance';
import { API_URL } from 'app/utils/urls';
import { getUserData, logoutUser } from 'app/utils/utils';
import { useSnackbar } from 'notistack';

const initialState = {
  user: null,
  registerData: null,
  loginData: null,
  logoutData: null,
  otp: null,
  // userEmail: '',
  isInitialised: false,
  isAuthenticated: false,
  userId: null,
  token: null
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem('accessToken');
//     // delete axios.defaults.headers.common.Authorization;
//   }
// };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      // const { user } = action.payload;
      return { ...state, isAuthenticated: true, loginData: action.payload };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      return { ...state, isAuthenticated: true, registerData: action.payload };
    }

    case 'VALIDATE_OTP': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    case 'SET_OTP':
      return { ...state, otp: action.payload };

    // case 'SET_SIGNUP_EMAIL': {
    //   const { userEmail } = action.payload;

    //   return { ...state };
    // }

    case 'FORGOT_PASSWORD': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    case 'RESET_PASSWORD': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }
    case 'CHANGE_USER_PASSWORD': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: async (email, password) => {},
  logout: () => {},
  register: () => {},
  setResetPasswordOtp: () => {},
  // setSignupEmail: () => {},
  validateOTP: () => {},
  forgotPassword: () => {},
  resetPassword: () => {},
  changeUserPassword: async (currentPassword, newPassword) => {},
  updateUser: async (payload) => {},
  userId: null
});

const errorInitialValue = {
  isError: false,
  errorMessage: 'Something went wrong'
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [error, setError] = useState(errorInitialValue);
  const { enqueueSnackbar } = useSnackbar();
  const [forgotOtp, setForgotOtp] = useState('');
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  let isAuthenticated = false;
  if (localStorage.getItem('accessToken')) {
    isAuthenticated = true;
  }

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post(API_URL.LOGIN, { email, password });
      const { user, status } = response?.data;
      dispatch({ type: 'LOGIN', payload: { user } });
      if (status === 'success') {
        const token = response?.data?.data?.token;
        const userData = response?.data?.data?.user;
        setError(errorInitialValue);
        if(!userData.subscriptionActive) {
          enqueueSnackbar("Your subscription has expired. You cannot create or edit notes without an active subsciption.", { variant: 'warning', persist: true, preventDuplicate: true });
        }
        if (token) {
          // setIsAuthenticated(true);
          localStorage.setItem('accessToken', token);
          localStorage.setItem('userData', JSON.stringify(userData));
          setUserId(userData?.userID);
          setUserInfo(userData);
        }
      } else if (response?.data?.status === 'error') {
        const userData = response?.data?.data?.user;
        if(userData) {
          enqueueSnackbar(response?.data?.error, { variant: 'warning' });
        } else {
          enqueueSnackbar(response?.data?.error, { variant: 'error' });
        }
      }
    } catch (error) {
      console.error('There was an error while logging in', { error });
      enqueueSnackbar('There was an error while logging in', { variant: 'error' });
    }
  };

  useEffect(() => {});

  const register = async (params) => {
    setUserEmail(params.workEmail);
    const response = await axiosInstance.post(API_URL.SIGNUP, params);
    // const { user } = response;
    console.log('data', response);
    dispatch({ type: 'REGISTER', payload: { registerData: response.data } });
    return response;
  };

  // const setSignupEmail = (email) => ({
  //   type: 'SET_SIGNUP_EMAIL',
  //   payload: email,
  // });

  // const setOTP = (otp) => ({ type: 'SET_OTP', payload: otp });

  const validateOTP = async (otp) => {
    console.log('userEmail', userEmail);
    const response = await axiosInstance.post(API_URL.VALIDATE_OTP, { email: userEmail, otp });
    const { user, token } = response.data.data;
    if (token) {
      // setIsAuthenticated(true);
      localStorage.setItem('accessToken', token);
      localStorage.setItem('userData', JSON.stringify(user));
      setUserId(user?.userID);
      setUserInfo(user);
    }
    dispatch({ type: 'VALIDATE_OTP', payload: { user } });
    return response;
  };

  const setResetPasswordOtp = (forgotPasswordKey) => {
    setForgotOtp(forgotPasswordKey);
  };

  const forgotPassword = async (email) => {
    setUserEmail(email);
    const response = await axiosInstance.post(API_URL.FORGOT_PASSWORD, { email });
    const { user } = response.data;

    dispatch({ type: 'FORGOT_PASSWORD', payload: { user } });
    return response;
  };

  const resetPassword = async (newPassword) => {
    try {
      const response = await axiosInstance.post(API_URL.RESET_PASSWORD, {
        email: userEmail,
        forgotPasswordKey: forgotOtp,
        newPassword
      });
      // const { user } = response.data;
      // dispatch({ type: 'RESET_PASSWORD', payload: { user } });
      return response;
    } catch (e) {
      console.log('error in reset password', e);
    }
  };

  const changeUserPassword = async (currentPassword, newPassword) => {
    const payload = {
      userID: userId,
      currentPassword: currentPassword,
      newPassword: newPassword
    };
    try {
      const response = await axiosInstance.post(API_URL.CHANGE_PASSWORD, payload);
      const { status, error } = response.data;
      if (status === 'error') {
        enqueueSnackbar(error, { variant: 'error' });
      } else if (status === 'success') {
        enqueueSnackbar('Password updated successfully', { variant: 'success' });
      }
    } catch (error) {
      console.error('Encountred an error while change password', { error, payload });
      enqueueSnackbar('Encountred an error while change password', { variant: 'error' });
    }
  };

  const updateUser = async (payload) => {
    try {
      const response = await axiosInstance.post(API_URL.UPDATE_USER, payload);
      const { status, error } = response.data;
      if (status === 'error') {
        enqueueSnackbar(error, { variant: 'error' });
      } else if (status === 'success') {
        enqueueSnackbar('User updated successfully', { variant: 'success' });
      }
    } catch (error) {
      console.error('Encountred an error while updating user', { error, payload });
      enqueueSnackbar('Encountred an error while updating user', { variant: 'error' });
    }
  };

  const logout = () => {
    logoutUser();
    // setIsAuthenticated(false);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    if (!userId) {
      const user = getUserData();
      setUserInfo(user);
      setUserId(user?.userID);
    }
  }, [userId]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data } = await axios.get('/api/auth/profile');
  //       dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data.user } });
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
  //     }
  //   })();
  // }, []);

  // SHOW LOADER
  // if (!state.isInitialised) return <Loading />;

  const config = {
    ...state,
    method: 'JWT',
    login,
    logout,
    register,
    // setSignupEmail,
    setResetPasswordOtp,
    validateOTP,
    forgotPassword,
    resetPassword,
    changeUserPassword,
    updateUser,
    setUserId,
    userId,
    userInfo,
    setUserInfo,
    error,
    setError,
    isAuthenticated
  };

  return <AuthContext.Provider value={config}>{children}</AuthContext.Provider>;
};

export default AuthContext;
