import { themes } from '../Theme/initThemes';
import LayoutSettings from './Layout/LayoutSettings';

export const PSLayoutSettings = {
  activeLayout: 'Layout', // Layout
  activeTheme: 'blue', // View all valid theme colors inside Theme/themeColors.js
  perfectScrollbar: false,
  themes: themes,
  LayoutSettings, // open Layout/LayoutSettings.js

  secondarySidebar: {
    show: true,
    open: false,
    theme: 'slateDark1' // View all valid theme colors inside Theme/themeColors.js
  },
  
  // Footer options
  footer: {
    show: true,
    fixed: false,
    theme: 'slateDark1' // View all valid theme colors inside Theme/themeColors.js
  }
};
